import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        component: () => import('../view/login.vue')

    },
    {
        path: '/index',
        name: 'index',
        component: () => import('../view/Main.vue'),
        meta: { requireAuth: true },
        children: [{
            path: '/home',
            component: () => import('../view/Home/index.vue'),
			meta:{
				keep:true
			}
        }, {

            path: '/Shopping',
            name: 'Shopping',
            component: () => import('../view/Shopping/index.vue')
        }, {

            path: '/my',
            name: 'my',
            component: () => import('../view/My/index.vue')
        },
        {
            name: 'address',
            path: '/address',
            component: () => import('../view/Address/address.vue')
        },
        {
            name: 'editAddress',
            path: '/editAddress/:type/:id',
            props: true,
            component: () => import('../view/Address/editAddress.vue')
        },
        {
            name: 'order',
            path: '/order/:id',
            props: true,
            component: () => import('../view/order/order.vue')
        },
        {
            name: 'integral',
            path: '/integral',
            component: () => import('../view/Integral/index.vue')
        },
        {
            name: 'details',
            path: '/details/:id',
            props: true,
            component: () => import('../view/details/index.vue')
        },
        {
            name: 'orderList',
            path: '/orderList/:id/:type',
            props: true,
            component: () => import('../view/orderList/index.vue')
        },
        {
            name: 'pass',
            path: '/pass',
            component: () => import('../view/pass/index.vue')
        }

        ]
    },
    { path: '*', redirect: '/' }
]
//  mode: 'history',
const router = new VueRouter({

    routes
})


export default router
